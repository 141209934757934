import type { PageComponentData } from '@/types/backend-controlled/backend-controlled'

type Props = {
  pageComponents: PageComponentData[] | undefined
}

const useInit = ({ pageComponents }: Props) => {
  const recommendedProducts = pageComponents
    ?.find(comp => comp.attributes.slotKey === 'recommended_product_list')
    ?.attributes.custom?.slice(0, 10)
  const limitedTimeOfferProducts = pageComponents?.find(
    comp => comp.attributes.slotKey === 'time_limited_product_list',
  )?.attributes.custom
  const latestCourses = pageComponents?.find(comp => comp.attributes.slotKey === 'latest_products')
    ?.attributes.custom
  const feedbacks = pageComponents?.find(comp => comp.attributes.slotKey === 'user_feedback')
    ?.attributes.custom
  const floatCampaign = pageComponents?.find(comp => comp.attributes.slotKey === 'float_campaign')
    ?.attributes.custom as
    | undefined
    | {
        startsAt: string
        endsAt: string
        activeImgUrl: string
        inactiveImgUrl: string
        targetUrl: string
      }
  const latestEvents = pageComponents?.find(comp => comp.attributes.slotKey === 'latest_events')
    ?.attributes?.custom?.items
  const subscriptionProducts = pageComponents?.find(
    comp => comp.attributes.slotKey === 'smart_learning',
  )?.attributes?.custom

  const ebookProducts = pageComponents?.find(
    comp => comp.attributes.slotKey === 'recommended_ebook_list',
  )?.attributes?.custom

  return {
    recommendedProducts,
    limitedTimeOfferProducts,
    latestCourses,
    feedbacks,
    floatCampaign,
    latestEvents,
    subscriptionProducts,
    ebookProducts,
  }
}

export default useInit
