import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Chip from '@/components/chip'
import safelyParseJson from '@/libs/utils/safely-parse-json'

type Keyword = {
  name: string
  link: string
}

const KeywordsSection = () => {
  const { t } = useTranslation()
  const keywords =
    safelyParseJson<Keyword[]>(t('crowdin:home-page.keywords-section.keywords')) || []
  return (
    <div className='flex w-full items-center justify-start gap-[12px] overflow-x-scroll px-4 hide-scrollbar mobileUp:justify-center'>
      {keywords.map(({ name, link }: Keyword) => {
        return (
          <Link href={link} key={name} className='no-underline'>
            <Chip>{name}</Chip>
          </Link>
        )
      })}
    </div>
  )
}

export default KeywordsSection
