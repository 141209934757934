import type { PageComponentData } from '@/types/backend-controlled/backend-controlled'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'

import UserFeedbacksSection from '@/components/backend-controlled-components/user-feedback'
import Footer from '@/components/elements/footer'
import KeywordsSection from './sections/KeywordsSection'
import ProductsSection from './sections/ProductsSection'
import QuantitativeDataSection from './sections/quantitative-data-section'
import SearchBarSection from './sections/search-bar-section'
import SubjectsSection from './sections/SubjectsSection'
import HeroSection from './sections/hero-section'
import RecommendationsSection from './sections/RecommendationsSection'
import useInit from './useInit'

const CampaignsSection = dynamic(import('./sections/CampaignsSection'), {
  ssr: false,
  loading: () => <div className='h-[287px]' />,
})
const ModulesSection = dynamic(import('./sections/modules-section'), { ssr: false })
const FaqSection = dynamic(import('./sections/FaqSection'), { ssr: false })
const PartnersSection = dynamic(import('./sections/partners-section'), { ssr: false })
const RenderInTimeBetween = dynamic(import('@/components/conditional-rendering/time-in-between'), {
  ssr: false,
})
const FloatCampaign = dynamic(import('@/components/backend-controlled-components/float-campaign'), {
  ssr: false,
})

interface Props {
  pageComponents: PageComponentData[] | undefined
  isAppHome?: boolean
}

const Homepage = ({ pageComponents, isAppHome }: Props) => {
  const {
    recommendedProducts,
    latestCourses,
    limitedTimeOfferProducts,
    feedbacks,
    floatCampaign,
    latestEvents,
    subscriptionProducts,
    ebookProducts,
  } = useInit({
    pageComponents,
  })
  const { t } = useTranslation()
  const recommendedProductsSectionTitle = t('crowdin:home-page.recommended-products-section-title')
  const limitedTimeOfferProductsSectionTitle = t(
    'crowdin:home-page.limited-time-offer-products-section-title',
  )
  const latestProductsSectionTitle = t('crowdin:home-page.latest-products-section-title')
  const subscriptionProductsSectionTitle = t(
    'crowdin:home-page.subscription-products-section-title',
  )
  const ebookProductsSectionTitle = t('crowdin:home-page.ebook-products-section-title')

  return (
    <div className='relative'>
      {!isAppHome && <HeroSection />}

      <SearchBarSection />
      <KeywordsSection />
      <SubjectsSection />

      {!isAppHome && <QuantitativeDataSection />}

      {!isAppHome && <UserFeedbacksSection feedbacks={feedbacks} />}

      <ProductsSection title={recommendedProductsSectionTitle} products={recommendedProducts} />
      <ProductsSection title={ebookProductsSectionTitle} products={ebookProducts} />
      <ProductsSection title={subscriptionProductsSectionTitle} products={subscriptionProducts} />
      <CampaignsSection latestEvents={latestEvents} />
      <ProductsSection
        title={limitedTimeOfferProductsSectionTitle}
        products={limitedTimeOfferProducts}
        filterSalePriceEndedProducts
      />
      <ProductsSection title={latestProductsSectionTitle} products={latestCourses} />

      {isAppHome && <div className='h-8' />}

      {!isAppHome && (
        <>
          <ModulesSection />
          <FaqSection />
          <PartnersSection />
          <Footer />
        </>
      )}

      {floatCampaign && (
        <RenderInTimeBetween startsAt={floatCampaign.startsAt} endsAt={floatCampaign.endsAt}>
          <FloatCampaign
            activeImgUrl={floatCampaign.activeImgUrl}
            inactiveImgUrl={floatCampaign.inactiveImgUrl}
            targetUrl={floatCampaign.targetUrl}
          />
        </RenderInTimeBetween>
      )}

      <RecommendationsSection />
    </div>
  )
}

export default Homepage
