import Image from 'next/image'
import dynamic from 'next/dynamic'
import { useState } from 'react'

import { useMobileUpMediaQuery } from '@/hooks/use-mobile-up-media-query'
import { VIDEO_HEIGHT, VIDEO_HEIGHT_MD, VIDEO_WIDTH, VIDEO_WIDTH_MD } from '../constants'
import { Slide } from './types'

const ENABLE_VIDEO = false

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

const HeroVideo = ({
  slide: { url, thumbnailUrl, alt },
  isPlaying,
}: {
  slide: Slide
  isPlaying: boolean
}) => {
  const [isVideoStart, setIsVideoStart] = useState(false)
  const isMobileUp = useMobileUpMediaQuery()
  const handleVideoStart = () => setIsVideoStart(true)
  return (
    <div className={'relative h-[349px] w-[305px] mobileUp:h-[450px] mobileUp:w-[396px]'}>
      <Image
        width={396}
        height={450}
        className={`${
          isVideoStart ? 'opacity-0' : 'opacity-100'
        } absolute z-20 h-full w-full animate-fade-in-300 rounded-[20px] transition-opacity duration-500 ease-in-out`}
        src={thumbnailUrl}
        alt={alt}
        priority
      />

      {ENABLE_VIDEO && (
        <div
          className={`overflow-hidden rounded-[20px] transition-opacity duration-700 ease-in-out ${
            isVideoStart ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <ReactPlayer
            url={url}
            controls={false}
            loop={true}
            width={isMobileUp ? VIDEO_WIDTH_MD : VIDEO_WIDTH}
            height={isMobileUp ? VIDEO_HEIGHT_MD : VIDEO_HEIGHT}
            muted={true}
            playing={isPlaying}
            onStart={handleVideoStart}
          />
        </div>
      )}
    </div>
  )
}

export default HeroVideo
