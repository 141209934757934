import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { Text } from '@libs-components/components/text'
import safelyParseJson from '@libs-components/utils/safely-parse-json'
import HorizontalSwiper from '@/components/horizontal-swiper'
import Image from '@/components/Image'

type Subject = {
  title: string
  icon: string
  link: string
  description?: string
}

const SubjectsSection = () => {
  const { t } = useTranslation()
  const subjects = safelyParseJson<Subject[]>(t('crowdin:home-page.subjects-section.subjects'), [])

  return (
    <HorizontalSwiper
      className='!mobileUp:px-0 !p-4'
      containerClassName='mx-auto max-w-[1260px]'
      slideClassName='!h-[112px] !w-[120px] rounded-2xl transition-colors duration-300 shadow-custom hover:bg-grayscale-100 hover:bg-opacity-30 !mobileUp:h-[120px] !mobileUp:w-[139px]'
      spaceBetween={20}
    >
      {subjects.map(({ title, icon, link, description }: Subject) => {
        return (
          <Link
            key={title}
            href={link}
            className='flex h-full w-full flex-col items-center justify-center no-underline'
          >
            <Image src={icon} alt={title} width={40} height={40} priority />
            <Text className='mt-2 clamp-line-2 hyphens-auto' variant='body_m_500'>
              {title}
            </Text>
            {description ? (
              <Text
                className='clamp-line-2 hyphens-auto'
                variant='label_s_500'
                color='grayscale-400'
              >
                {description}
              </Text>
            ) : null}
          </Link>
        )
      })}
    </HorizontalSwiper>
  )
}

export default SubjectsSection
