import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'
import { shopPaths as SHOP_PATH } from '@/libs/routes'
import useGlobalLoader from '@/hooks/use-global-loader'

const IS_SUBMITTED_IN_A_SESSION = 'is_submitted_in_a_session'
const useInit = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const [hintFadeInUp, setHintFadeInUp] = useState('')
  const { startLoading, stopLoading } = useGlobalLoader()
  const timerRef = useRef<number>()

  const onSubmit = async (searchParams: URLSearchParams) => {
    startLoading()
    window.sessionStorage.setItem(IS_SUBMITTED_IN_A_SESSION, 'true')
    await router.push({
      pathname: SHOP_PATH.RECOMMENDATIONS_RESULT(),
      query: Object.fromEntries(searchParams.entries()),
    })
    stopLoading()
  }

  useEffect(() => {
    if (hintFadeInUp && timerRef.current) {
      window.clearTimeout(timerRef.current)
    }
  }, [hintFadeInUp])

  useEffect(() => {
    if (window.sessionStorage.getItem(IS_SUBMITTED_IN_A_SESSION) === 'true') return
    const intervalSeconds = +t('crowdin:recommendations-assistance.modal-detect-interval-seconds')
    if (Number.isFinite(intervalSeconds)) {
      timerRef.current = window.setTimeout(() => {
        setHintFadeInUp('animate-fade-in-up')
        window.clearTimeout(timerRef.current)
      }, intervalSeconds * 1000)
      return () => {
        if (timerRef.current) window.clearTimeout(timerRef.current)
      }
    }
  }, [t])

  return { store: { hintFadeInUp }, action: { onSubmit } }
}

export default useInit
