import { useTranslation } from 'next-i18next'
import { Text } from '@libs-components/components/text'
import Slides from './slides'

const HeroSection = () => {
  const { t } = useTranslation()
  const title = t('crowdin:home-page.hero-section.title')
  const description = t('crowdin:home-page.hero-section.description')
  return (
    <div className='flex w-full flex-col items-center bg-gradient-to-r from-purple-500 to-primary-400 py-[30px] mobileUp:py-[60px]'>
      <Text
        variant='headline_xl_500'
        color='grayscale-000'
        className='px-10 text-center leading-[1.5] tracking-[1.4px] mobileUp:text-[48px] mobileUp:tracking-[2.4px]'
      >
        {title}
      </Text>
      <Text
        variant='body_l_500'
        color='grayscale-000'
        className='px-10 text-[14px] leading-[1.75] tracking-[4.2px]'
      >
        {description}
      </Text>
      <Slides />
    </div>
  )
}

export default HeroSection
