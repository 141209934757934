import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { memo } from 'react'
import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'
import { getStarIconNames } from './utils'

type Props = {
  rating: number
  ratingCount?: number
  showText: boolean
  className?: string
  isClickable?: boolean
  isDisplayedOnlyOneStarIcon?: boolean
}

const RatingStars = ({
  rating,
  ratingCount,
  showText,
  className,
  isClickable = false,
  isDisplayedOnlyOneStarIcon = true,
}: Props) => {
  const { t } = useTranslation('feature-product')
  const ratingStr = rating
    ? Number.isFinite(rating)
      ? rating.toFixed(1)
      : '0'
    : `(${t('rating.collecting')})`
  const icons = isDisplayedOnlyOneStarIcon ? ['star_filled' as const] : getStarIconNames(ratingStr)

  return (
    <div className={twMerge('flex items-center gap-1', className)}>
      <div className='flex items-center gap-1'>
        {icons.map((iconType, idx) => (
          <Icon key={`${iconType}-${idx}`} type={iconType} style={{ color: '#ffc914' }} />
        ))}
      </div>
      {showText && (
        <>
          <Text variant='body_m_500'>{ratingStr}</Text>
          {!!ratingCount && (
            <Text variant='body_m_500'>
              {'('}
              <span className={twMerge(isClickable && 'underline underline-offset-1')}>
                {ratingCount}
              </span>
              {')'}
            </Text>
          )}
        </>
      )}
    </div>
  )
}

export default memo(RatingStars)
