import { useTranslation } from 'next-i18next'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import { Text } from '@libs-components/components/text'

const mockNumber = 10000000

const QuantitativeDataSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const { t } = useTranslation('homepage')

  return (
    <div className='grid grid-cols-[auto_auto] grid-rows-[auto_auto_auto_auto] pb-10 mobileUp:grid-cols-[1fr_auto_1fr] mobileUp:grid-rows-[auto_auto] mobileUp:gap-x-3'>
      <Text
        variant='heading_m_500'
        className='col-span-2 row-start-1 self-center text-center text-sm text-grayscale-800 mobileUp:col-span-1 mobileUp:col-start-1 mobileUp:text-right mobileUp:text-xl'
      >
        {t('number_section.first_part')}
      </Text>

      <div
        ref={ref}
        className='relative col-span-2 col-start-1 row-start-2 bg-gradient-to-r from-purple-500 to-primary-500 bg-clip-text text-center text-[56px] font-black leading-[1.75] text-transparent mobileUp:col-span-1 mobileUp:col-start-2 mobileUp:row-start-1 mobileUp:text-[80px]'
      >
        {inView && <CountUp end={mockNumber} />}
        <span className='relative top-[-15px] text-[56px] font-black leading-[1.75] text-primary-500 mobileUp:top-[-25px]'>
          +
        </span>
      </div>
      <Text
        variant='heading_m_500'
        className='col-span-1 col-start-1 row-start-3 self-center text-right text-sm text-grayscale-800 mobileUp:col-start-3 mobileUp:row-start-1 mobileUp:text-left mobileUp:text-xl'
      >
        {t('number_section.second_part')}
        <span className='mobileUp:invisible'>{t('number_section.separator')}</span>
      </Text>
      <Text
        variant='heading_m_500'
        className='col-span-1 col-start-2 row-start-3 self-center whitespace-pre-wrap text-left text-sm text-grayscale-800 mobileUp:col-span-3 mobileUp:col-start-1 mobileUp:row-start-2 mobileUp:justify-self-center mobileUp:text-center mobileUp:text-xl'
      >
        {t('number_section.third_part')}
      </Text>
    </div>
  )
}

export default QuantitativeDataSection
