type Props = {
  children: React.ReactNode
}

const Chip = ({ children }: Props) => {
  return (
    <div className='whitespace-nowrap rounded-[16px] bg-grayscale-900 px-[16px] py-[5.5px] text-[0.75rem] leading-[1.3125rem] tracking-[0.0025em] text-grayscale-000 hover:bg-grayscale-700'>
      {children}
    </div>
  )
}

export default Chip
