const VerticalCardSkeleton = () => {
  return (
    <div className='flex w-[258px] flex-col gap-2'>
      <div className='h-[143px] animate-pulse rounded-lg bg-grayscale-100' />
      <div className='flex items-center gap-2'>
        <div className='h-[24px] w-[66px] animate-pulse rounded-full bg-grayscale-100' />
        <div className='h-[24px] w-[66px] animate-pulse rounded-full bg-grayscale-100' />
        <div className='h-[24px] w-[66px] animate-pulse rounded-full bg-grayscale-100' />
      </div>
      <div className='h-[52px] animate-pulse bg-grayscale-100' />
      <div className='h-[33px] animate-pulse bg-grayscale-100' />
      <div className='h-[46px] animate-pulse bg-grayscale-100' />
      <div className='h-[33px] animate-pulse bg-grayscale-100' />
    </div>
  )
}

export default VerticalCardSkeleton
